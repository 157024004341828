import React from 'react';
import * as Icons from "./Icons";
import { systemError as Colors } from './Colors';
import Apologize from '../image/apologize.png';

export default class SysytemErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.wrap_style = {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    }
    this.headerArea_style = {
      display: 'flex',
      justifyContent: 'center',
      padding: '40px 0px',
      backgroundColor: '#FFFFFF',
    }
    this.headingWrap_style = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      paddingLeft: 24,
    }
    this.apologizeShadow_style = {
      width: 60,
      height: 16,
      backgroundColor: '#B5B5B5',
      borderRadius: '100%',
      position: 'absolute',
      right: '100%',
      top: 140,
    }
    this.apologize_style = {
      filter: 'invert(80%) sepia(80%) saturate(5000%) hue-rotate(60deg) brightness(70%)',
      height: 150,
      width: 60,
      position: 'absolute',
      right: '100%',
      top: 0,
    }
    this.heading_style = {
      fontSize: 20,
      fontWeight: 'bold',
      letterSpacing: 1,
      color: '#65A36F',
      marginBottom: 16,
    }
    this.subHeading_style = {
      fontWeight: 'bold',
      color: '#939393',
      lineHeight: 1.5,
      fontSize: 14,
    }
    this.detailArea_style = {
    }
    this.iconRows_style = {
      display: 'flex',
      justifyContent: 'space-around',
      padding: 4,
    }
    this.errorWrap_style = {
      marginTop: 20,
    }
    this.error_style = {
      width: '100%',
      backgroundColor: '#FFFFFF',
      minHeight: 200,
      border: '1px solid #D9D9D9',
      borderRadius: '10px 10px 0px 0px',
    }
    this.errorFooter_style = {
      borderRadius: '0px 0px 10px 10px',
      height: 60,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      backgroundColor: '#F5F5F5',
      border: '1px solid #D9D9D9',
      alignItems: 'center',
      padding: '0px 12px'
    }
  }
  render() {
    const { errorValue } = this.props;
    console.log(this.props)
    return (
      <div style={this.wrap_style}>
        <div style={this.headerArea_style}>
          <div style={this.headingWrap_style}>
            <div style={this.apologizeShadow_style} />
            <img src={Apologize} alt='謝る人' style={this.apologize_style} />
            <h1 style={this.heading_style}>
              システムエラーが発生しました
            </h1>
            <div style={this.subHeading_style}>
              ご迷惑をおかけしております。<br />
              以下のプログラムでエラーが発生しております
            </div>
          </div>
        </div>
        <div style={this.detailArea_style}>
          <div style={this.iconRows_style}>
            <Icons.Meadow width={20} height={20} color={Colors.icon} />
            <Icons.Meadow width={20} height={20} color={Colors.icon} />
            <Icons.Meadow width={20} height={20} color={Colors.icon} />
            <Icons.Meadow width={20} height={20} color={Colors.icon} />
            <Icons.Meadow width={20} height={20} color={Colors.icon} />
          </div>
          <div style={this.iconRows_style}>
            <Icons.Meadow width={20} height={20} color={Colors.icon} />
            <Icons.Meadow width={20} height={20} color={Colors.icon} />
            <Icons.Meadow width={20} height={20} color={Colors.icon} />
            <Icons.Meadow width={20} height={20} color={Colors.icon} />
          </div>
          <div style={this.errorWrap_style}>
            <div style={this.error_style}>
              {errorValue}
            </div>
            <div style={this.errorFooter_style}>
              {/* <Btn label='詳細' /> */}
              {/* <Btn label='OK' main /> */}
              <div />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Btn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      focus: false,
    }
  }
  render() {
    const { label, main } = this.props;
    const { hover, focus } = this.state;

    const btn_style = {
      backgroundColor:
        hover || focus ? Colors.btnhover :
          Colors.btn,
      border: 'none',
      color: '#FFFFFF',
      fontSize: 14,
      fontWeight: 'bold',
      height: 36,
      borderRadius: 2,
      cursor: 'pointer',
      width:
        main ? 200 :
          null,
      padding:
        main ? '0px 8px' :
          '0px 32px',
    }
    return (
      <button
        style={btn_style}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        {label}
      </button>
    )
  }
}